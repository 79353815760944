@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* @import './sass/estilos.scss'; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 16px; */
}

body {
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  // background-color: #efefef;
  min-height: 100vh;
  min-height: 100dvh;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  .content {
    flex-grow: 1;
    padding: 0px 20px;
  }
}

form {
  /* input { */
  /*   font-size: 16px; */
  /* } */
  /* button { */
  /*   border: none; */
  /*   padding: 10px 10px; */
  /* } */
}

div.tabla {
  /* display: table; */
  /* width: 100%; */
  /* border-radius: 20px; */
  /* box-shadow: 0px 0px 5px -3px black; */
  /* overflow: hidden; */
  /* .fila { */
  /*   display: table-row; */
  /* } */
  /* .celda { */
  /*   display: table-cell; */
  /*   padding: 10px; */
  /* } */
  /* .tabla-cabecera { */
  /*   display: table-header-group; */
  /*   /* background-color: $colorPrimario; */
  /*   .celda { */
  /*     /* background-color: $colorPrimario; */
  /*     font-weight: bold; */
  /*     /* color: $blanco; */
  /*   } */
  /* } */
  /* .tabla-cuerpo { */
  /*   display: table-row-group; */
  /*   .celda { */
  /*     /* background-color: $blanco; */
  /*   } */
  /* } */
}

/* @import './sass/login.scss'; */
/* @import './sass/menu.scss'; */
/* @import './sass/header.scss'; */

/* Colores
---------------------------------------- */
.color_libra_marron {
  color: #795548 !important;
}
.color_libra_marron_oscuro {
  color: #3e2723 !important;
}
.color_libra_marron_intermedio {
  color: #a1887f !important;
}
.color_libra_morado {
  color: #9c27b0 !important;
}
.color_libra_cyan {
  color: #00bcd4 !important;
}
.color_libra_azul {
  color: #21508E !important;
}
.color_libra_azul_claro {
  color: #33B5E5 !important;
}
.color_libra_azul_intermedio {
  color: #1565c0 !important;
}
.color_libra_verde_claro {
  color: #9C0 !important;
}
.color_libra_naranja {
  color: #F80 !important;
}
.color_libra_blanco {
  color: #fff !important;
}
.color_libra_marron_claro {
  color: #d7ccc8 !important;
}
.color_libra_fuxia {
  color: #ad1457 !important;
}
.color_libra_azul_verdoso {
  color: #009688 !important;
}
.color_libra_mate {
  color: #cddc39 !important;
}
.color_libra_negro {
  color: #000 !important;
}
.color_libra_azul_morado {
  color: #5c6bc0 !important;
}
.color_libra_verde_oscuro {
  color: #1b5e20 !important;
}
.color_libra_amarillo_naranja {
  color: #ffd600 !important;
}
.color_libra_azul_oscuro {
  color: #0d47a1 !important;
}
.color_libra_rosaceo {
  color: #ec407a !important;
}
.color_libra_amarillo {
  color: #ffeb3b !important;
}
.color_libra_verde {
  color: #690 !important;
}
.color_libra_rojo {
  color: #C00 !important;
}
.color_libra_md_verde_claro {
  color: #cddc39 !important;
}
.color_libra_md_marron {
  color: #d35548 !important;
}
.color_libra_md_morado {
  color: #e91e63 !important;
}
.color_libra_md_amarillo_naranja {
  color: #ffc107 !important;
}
.color_libra_md_amarillo {
  color: #ffeb3b !important;
}
.color_libra_md_naranja_oscuro {
  color: #ff4336 !important;
}
.color_libra_md_naranja {
  color: #ff9800 !important;
}
.color_libra_md_rojo {
  color: #f44336 !important;
}
.color_libra_md_azul_claro {
  color: #00bcd4 !important;
}
.color_libra_md_azul_verdoso {
  color: #009688 !important;
}
.color_libra_md_azul {
  color: #03a9f4 !important;
}
.color_libra_md_azul_intermedio {
  color: #2196f3 !important;
}
.color_libra_md_azul_oscuro {
  color: #3f51b5 !important;
}
.color_libra_md_verde_oscuro {
  color: #4caf50 !important;
}
.color_libra_md_gris {
  color: #607d8b !important;
}
.color_libra_md_violeta {
  color: #673ab7 !important;
}
.color_libra_md_verde {
  color: #8bc34a !important;
}
.color_libra_md_fuxia {
  color: #9c27b0 !important;
}
.color_libra_md_gris_claro {
  color: #9e9e9e !important;
}
.color_libra_red {
  color: #f44336 !important;
}
.color_libra_red_50 {
  color: #ffebee !important;
}
.color_libra_red_100 {
  color: #ffcdd2 !important;
}
.color_libra_red_200 {
  color: #ef9a9a !important;
}
.color_libra_red_300 {
  color: #e57373 !important;
}
.color_libra_red_400 {
  color: #ef5350 !important;
}
.color_libra_red_500 {
  color: #f44336 !important;
}
.color_libra_red_600 {
  color: #e53935 !important;
}
.color_libra_red_700 {
  color: #d32f2f !important;
}
.color_libra_red_800 {
  color: #c62828 !important;
}
.color_libra_red_900 {
  color: #b71c1c !important;
}
.color_libra_red_a100 {
  color: #ff8a80 !important;
}
.color_libra_red_a200 {
  color: #ff5252 !important;
}
.color_libra_red_a400 {
  color: #ff1744 !important;
}
.color_libra_red_a700 {
  color: #d50000 !important;
}
.color_libra_pink {
  color: #e91e63 !important;
}
.color_libra_pink_50 {
  color: #fce4ec !important;
}
.color_libra_pink_100 {
  color: #f8bbd0 !important;
}
.color_libra_pink_200 {
  color: #f48fb1 !important;
}
.color_libra_pink_300 {
  color: #f06292 !important;
}
.color_libra_pink_400 {
  color: #ec407a !important;
}
.color_libra_pink_500 {
  color: #e91e63 !important;
}
.color_libra_pink_600 {
  color: #d81b60 !important;
}
.color_libra_pink_700 {
  color: #c2185b !important;
}
.color_libra_pink_800 {
  color: #ad1457 !important;
}
.color_libra_pink_900 {
  color: #880e4f !important;
}
.color_libra_pink_a100 {
  color: #ff80ab !important;
}
.color_libra_pink_a200 {
  color: #ff4081 !important;
}
.color_libra_pink_a400 {
  color: #f50057 !important;
}
.color_libra_pink_a700 {
  color: #c51162 !important;
}
.color_libra_purple {
  color: #9c27b0 !important;
}
.color_libra_purple_50 {
  color: #f3e5f5 !important;
}
.color_libra_purple_100 {
  color: #e1bee7 !important;
}
.color_libra_purple_200 {
  color: #ce93d8 !important;
}
.color_libra_purple_300 {
  color: #ba68c8 !important;
}
.color_libra_purple_400 {
  color: #ab47bc !important;
}
.color_libra_purple_500 {
  color: #9c27b0 !important;
}
.color_libra_purple_600 {
  color: #8e24aa !important;
}
.color_libra_purple_700 {
  color: #7b1fa2 !important;
}
.color_libra_purple_800 {
  color: #6a1b9a !important;
}
.color_libra_purple_900 {
  color: #4a148c !important;
}
.color_libra_purple_a100 {
  color: #ea80fc !important;
}
.color_libra_purple_a200 {
  color: #e040fb !important;
}
.color_libra_purple_a400 {
  color: #d500f9 !important;
}
.color_libra_purple_a700 {
  color: #aa00ff !important;
}
.color_libra_deep_purple {
  color: #673ab7 !important;
}
.color_libra_deep_purple_50 {
  color: #ede7f6 !important;
}
.color_libra_deep_purple_100 {
  color: #d1c4e9 !important;
}
.color_libra_deep_purple_200 {
  color: #b39ddb !important;
}
.color_libra_deep_purple_300 {
  color: #9575cd !important;
}
.color_libra_deep_purple_400 {
  color: #7e57c2 !important;
}
.color_libra_deep_purple_500 {
  color: #673ab7 !important;
}
.color_libra_deep_purple_600 {
  color: #5e35b1 !important;
}
.color_libra_deep_purple_700 {
  color: #512da8 !important;
}
.color_libra_deep_purple_800 {
  color: #4527a0 !important;
}
.color_libra_deep_purple_900 {
  color: #311b92 !important;
}
.color_libra_deep_purple_a100 {
  color: #b388ff !important;
}
.color_libra_deep_purple_a200 {
  color: #7c4dff !important;
}
.color_libra_deep_purple_a400 {
  color: #651fff !important;
}
.color_libra_deep_purple_a700 {
  color: #6200ea !important;
}
.color_libra_indigo {
  color: #3f51b5 !important;
}
.color_libra_indigo_50 {
  color: #e8eaf6 !important;
}
.color_libra_indigo_100 {
  color: #c5cae9 !important;
}
.color_libra_indigo_200 {
  color: #9fa8da !important;
}
.color_libra_indigo_300 {
  color: #7986cb !important;
}
.color_libra_indigo_400 {
  color: #5c6bc0 !important;
}
.color_libra_indigo_500 {
  color: #3f51b5 !important;
}
.color_libra_indigo_600 {
  color: #3949ab !important;
}
.color_libra_indigo_700 {
  color: #303f9f !important;
}
.color_libra_indigo_800 {
  color: #283593 !important;
}
.color_libra_indigo_900 {
  color: #1a237e !important;
}
.color_libra_indigo_a100 {
  color: #8c9eff !important;
}
.color_libra_indigo_a200 {
  color: #536dfe !important;
}
.color_libra_indigo_a400 {
  color: #3d5afe !important;
}
.color_libra_indigo_a700 {
  color: #304ffe !important;
}
.color_libra_blue {
  color: #2196f3 !important;
}
.color_libra_blue_50 {
  color: #e3f2fd !important;
}
.color_libra_blue_100 {
  color: #bbdefb !important;
}
.color_libra_blue_200 {
  color: #90caf9 !important;
}
.color_libra_blue_300 {
  color: #64b5f6 !important;
}
.color_libra_blue_400 {
  color: #42a5f5 !important;
}
.color_libra_blue_500 {
  color: #2196f3 !important;
}
.color_libra_blue_600 {
  color: #1e88e5 !important;
}
.color_libra_blue_700 {
  color: #1976d2 !important;
}
.color_libra_blue_800 {
  color: #1565c0 !important;
}
.color_libra_blue_900 {
  color: #0d47a1 !important;
}
.color_libra_blue_a100 {
  color: #82b1ff !important;
}
.color_libra_blue_a200 {
  color: #448aff !important;
}
.color_libra_blue_a400 {
  color: #2979ff !important;
}
.color_libra_blue_a700 {
  color: #2962ff !important;
}
.color_libra_light_blue {
  color: #03a9f4 !important;
}
.color_libra_light_blue_50 {
  color: #e1f5fe !important;
}
.color_libra_light_blue_100 {
  color: #b3e5fc !important;
}
.color_libra_light_blue_200 {
  color: #81d4fa !important;
}
.color_libra_light_blue_300 {
  color: #4fc3f7 !important;
}
.color_libra_light_blue_400 {
  color: #29b6f6 !important;
}
.color_libra_light_blue_500 {
  color: #03a9f4 !important;
}
.color_libra_light_blue_600 {
  color: #039be5 !important;
}
.color_libra_light_blue_700 {
  color: #0288d1 !important;
}
.color_libra_light_blue_800 {
  color: #0277bd !important;
}
.color_libra_light_blue_900 {
  color: #01579b !important;
}
.color_libra_light_blue_a100 {
  color: #80d8ff !important;
}
.color_libra_light_blue_a200 {
  color: #40c4ff !important;
}
.color_libra_light_blue_a400 {
  color: #00b0ff !important;
}
.color_libra_light_blue_a700 {
  color: #0091ea !important;
}
.color_libra_cyan_50 {
  color: #e0f7fa !important;
}
.color_libra_cyan_100 {
  color: #b2ebf2 !important;
}
.color_libra_cyan_200 {
  color: #80deea !important;
}
.color_libra_cyan_300 {
  color: #4dd0e1 !important;
}
.color_libra_cyan_400 {
  color: #26c6da !important;
}
.color_libra_cyan_500 {
  color: #00bcd4 !important;
}
.color_libra_cyan_600 {
  color: #00acc1 !important;
}
.color_libra_cyan_700 {
  color: #0097a7 !important;
}
.color_libra_cyan_800 {
  color: #00838f !important;
}
.color_libra_cyan_900 {
  color: #006064 !important;
}
.color_libra_cyan_a100 {
  color: #84ffff !important;
}
.color_libra_cyan_a200 {
  color: #18ffff !important;
}
.color_libra_cyan_a400 {
  color: #00e5ff !important;
}
.color_libra_cyan_a700 {
  color: #00b8d4 !important;
}
.color_libra_teal {
  color: #009688 !important;
}
.color_libra_teal_50 {
  color: #e0f2f1 !important;
}
.color_libra_teal_100 {
  color: #b2dfdb !important;
}
.color_libra_teal_200 {
  color: #80cbc4 !important;
}
.color_libra_teal_300 {
  color: #4db6ac !important;
}
.color_libra_teal_400 {
  color: #26a69a !important;
}
.color_libra_teal_500 {
  color: #009688 !important;
}
.color_libra_teal_600 {
  color: #00897b !important;
}
.color_libra_teal_700 {
  color: #00796b !important;
}
.color_libra_teal_800 {
  color: #00695c !important;
}
.color_libra_teal_900 {
  color: #004d40 !important;
}
.color_libra_teal_a100 {
  color: #a7ffeb !important;
}
.color_libra_teal_a200 {
  color: #64ffda !important;
}
.color_libra_teal_a400 {
  color: #1de9b6 !important;
}
.color_libra_teal_a700 {
  color: #00bfa5 !important;
}
.color_libra_green {
  color: #4caf50 !important;
}
.color_libra_green_50 {
  color: #e8f5e9 !important;
}
.color_libra_green_100 {
  color: #c8e6c9 !important;
}
.color_libra_green_200 {
  color: #a5d6a7 !important;
}
.color_libra_green_300 {
  color: #81c784 !important;
}
.color_libra_green_400 {
  color: #66bb6a !important;
}
.color_libra_green_500 {
  color: #4caf50 !important;
}
.color_libra_green_600 {
  color: #43a047 !important;
}
.color_libra_green_700 {
  color: #388e3c !important;
}
.color_libra_green_800 {
  color: #2e7d32 !important;
}
.color_libra_green_900 {
  color: #1b5e20 !important;
}
.color_libra_green_a100 {
  color: #b9f6ca !important;
}
.color_libra_green_a200 {
  color: #69f0ae !important;
}
.color_libra_green_a400 {
  color: #00e676 !important;
}
.color_libra_green_a700 {
  color: #00c853 !important;
}
.color_libra_light_green {
  color: #8bc34a !important;
}
.color_libra_light_green_50 {
  color: #f1f8e9 !important;
}
.color_libra_light_green_100 {
  color: #dcedc8 !important;
}
.color_libra_light_green_200 {
  color: #c5e1a5 !important;
}
.color_libra_light_green_300 {
  color: #aed581 !important;
}
.color_libra_light_green_400 {
  color: #9ccc65 !important;
}
.color_libra_light_green_500 {
  color: #8bc34a !important;
}
.color_libra_light_green_600 {
  color: #7cb342 !important;
}
.color_libra_light_green_700 {
  color: #689f38 !important;
}
.color_libra_light_green_800 {
  color: #558b2f !important;
}
.color_libra_light_green_900 {
  color: #33691e !important;
}
.color_libra_light_green_a100 {
  color: #ccff90 !important;
}
.color_libra_light_green_a200 {
  color: #b2ff59 !important;
}
.color_libra_light_green_a400 {
  color: #76ff03 !important;
}
.color_libra_light_green_a700 {
  color: #64dd17 !important;
}
.color_libra_lime {
  color: #cddc39 !important;
}
.color_libra_lime_50 {
  color: #f9fbe7 !important;
}
.color_libra_lime_100 {
  color: #f0f4c3 !important;
}
.color_libra_lime_200 {
  color: #e6ee9c !important;
}
.color_libra_lime_300 {
  color: #dce775 !important;
}
.color_libra_lime_400 {
  color: #d4e157 !important;
}
.color_libra_lime_500 {
  color: #cddc39 !important;
}
.color_libra_lime_600 {
  color: #c0ca33 !important;
}
.color_libra_lime_700 {
  color: #afb42b !important;
}
.color_libra_lime_800 {
  color: #9e9d24 !important;
}
.color_libra_lime_900 {
  color: #827717 !important;
}
.color_libra_lime_a100 {
  color: #f4ff81 !important;
}
.color_libra_lime_a200 {
  color: #eeff41 !important;
}
.color_libra_lime_a400 {
  color: #c6ff00 !important;
}
.color_libra_lime_a700 {
  color: #aeea00 !important;
}
.color_libra_yellow {
  color: #ffeb3b !important;
}
.color_libra_yellow_50 {
  color: #fffde7 !important;
}
.color_libra_yellow_100 {
  color: #fff9c4 !important;
}
.color_libra_yellow_200 {
  color: #fff59d !important;
}
.color_libra_yellow_300 {
  color: #fff176 !important;
}
.color_libra_yellow_400 {
  color: #ffee58 !important;
}
.color_libra_yellow_500 {
  color: #ffeb3b !important;
}
.color_libra_yellow_600 {
  color: #fdd835 !important;
}
.color_libra_yellow_700 {
  color: #fbc02d !important;
}
.color_libra_yellow_800 {
  color: #f9a825 !important;
}
.color_libra_yellow_900 {
  color: #f57f17 !important;
}
.color_libra_yellow_a100 {
  color: #ffff8d !important;
}
.color_libra_yellow_a200 {
  color: #ffff00 !important;
}
.color_libra_yellow_a400 {
  color: #ffea00 !important;
}
.color_libra_yellow_a700 {
  color: #ffd600 !important;
}
.color_libra_amber {
  color: #ffc107 !important;
}
.color_libra_amber_50 {
  color: #fff8e1 !important;
}
.color_libra_amber_100 {
  color: #ffecb3 !important;
}
.color_libra_amber_200 {
  color: #ffe082 !important;
}
.color_libra_amber_300 {
  color: #ffd54f !important;
}
.color_libra_amber_400 {
  color: #ffca28 !important;
}
.color_libra_amber_500 {
  color: #ffc107 !important;
}
.color_libra_amber_600 {
  color: #ffb300 !important;
}
.color_libra_amber_700 {
  color: #ffa000 !important;
}
.color_libra_amber_800 {
  color: #ff8f00 !important;
}
.color_libra_amber_900 {
  color: #ff6f00 !important;
}
.color_libra_amber_a100 {
  color: #ffe57f !important;
}
.color_libra_amber_a200 {
  color: #ffd740 !important;
}
.color_libra_amber_a400 {
  color: #ffc400 !important;
}
.color_libra_amber_a700 {
  color: #ffab00 !important;
}
.color_libra_orange {
  color: #ff9800 !important;
}
.color_libra_orange_50 {
  color: #fff3e0 !important;
}
.color_libra_orange_100 {
  color: #ffe0b2 !important;
}
.color_libra_orange_200 {
  color: #ffcc80 !important;
}
.color_libra_orange_300 {
  color: #ffb74d !important;
}
.color_libra_orange_400 {
  color: #ffa726 !important;
}
.color_libra_orange_500 {
  color: #ff9800 !important;
}
.color_libra_orange_600 {
  color: #fb8c00 !important;
}
.color_libra_orange_700 {
  color: #f57c00 !important;
}
.color_libra_orange_800 {
  color: #ef6c00 !important;
}
.color_libra_orange_900 {
  color: #e65100 !important;
}
.color_libra_orange_a100 {
  color: #ffd180 !important;
}
.color_libra_orange_a200 {
  color: #ffab40 !important;
}
.color_libra_orange_a400 {
  color: #ff9100 !important;
}
.color_libra_orange_a700 {
  color: #ff6d00 !important;
}
.color_libra_deep_orange {
  color: #ff5722 !important;
}
.color_libra_deep_orange_50 {
  color: #fbe9e7 !important;
}
.color_libra_deep_orange_100 {
  color: #ffccbc !important;
}
.color_libra_deep_orange_200 {
  color: #ffab91 !important;
}
.color_libra_deep_orange_300 {
  color: #ff8a65 !important;
}
.color_libra_deep_orange_400 {
  color: #ff7043 !important;
}
.color_libra_deep_orange_500 {
  color: #ff5722 !important;
}
.color_libra_deep_orange_600 {
  color: #f4511e !important;
}
.color_libra_deep_orange_700 {
  color: #e64a19 !important;
}
.color_libra_deep_orange_800 {
  color: #d84315 !important;
}
.color_libra_deep_orange_900 {
  color: #bf360c !important;
}
.color_libra_deep_orange_a100 {
  color: #ff9e80 !important;
}
.color_libra_deep_orange_a200 {
  color: #ff6e40 !important;
}
.color_libra_deep_orange_a400 {
  color: #ff3d00 !important;
}
.color_libra_deep_orange_a700 {
  color: #dd2c00 !important;
}
.color_libra_brown {
  color: #795548 !important;
}
.color_libra_brown_50 {
  color: #efebe9 !important;
}
.color_libra_brown_100 {
  color: #d7ccc8 !important;
}
.color_libra_brown_200 {
  color: #bcaaa4 !important;
}
.color_libra_brown_300 {
  color: #a1887f !important;
}
.color_libra_brown_400 {
  color: #8d6e63 !important;
}
.color_libra_brown_500 {
  color: #795548 !important;
}
.color_libra_brown_600 {
  color: #6d4c41 !important;
}
.color_libra_brown_700 {
  color: #5d4037 !important;
}
.color_libra_brown_800 {
  color: #4e342e !important;
}
.color_libra_brown_900 {
  color: #3e2723 !important;
}
.color_libra_grey {
  color: #9e9e9e !important;
}
.color_libra_grey_50 {
  color: #fafafa !important;
}
.color_libra_grey_100 {
  color: #f5f5f5 !important;
}
.color_libra_grey_200 {
  color: #eeeeee !important;
}
.color_libra_grey_300 {
  color: #e0e0e0 !important;
}
.color_libra_grey_400 {
  color: #bdbdbd !important;
}
.color_libra_grey_500 {
  color: #9e9e9e !important;
}
.color_libra_grey_600 {
  color: #757575 !important;
}
.color_libra_grey_700 {
  color: #616161 !important;
}
.color_libra_grey_800 {
  color: #424242 !important;
}
.color_libra_grey_900 {
  color: #212121 !important;
}
.color_libra_blue_grey {
  color: #607d8b !important;
}
.color_libra_blue_grey_50 {
  color: #eceff1 !important;
}
.color_libra_blue_grey_100 {
  color: #cfd8dc !important;
}
.color_libra_blue_grey_200 {
  color: #b0bec5 !important;
}
.color_libra_blue_grey_300 {
  color: #90a4ae !important;
}
.color_libra_blue_grey_400 {
  color: #78909c !important;
}
.color_libra_blue_grey_500 {
  color: #607d8b !important;
}
.color_libra_blue_grey_600 {
  color: #546e7a !important;
}
.color_libra_blue_grey_700 {
  color: #455a64 !important;
}
.color_libra_blue_grey_800 {
  color: #37474f !important;
}
.color_libra_blue_grey_900 {
  color: #263238 !important;
}

.color_libra_marron_bg {
  background-color: #795548 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_marron_oscuro_bg {
  background-color: #3e2723 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_marron_intermedio_bg {
  background-color: #a1887f !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_morado_bg {
  background-color: #9c27b0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_cyan_bg {
  background-color: #00bcd4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_azul_bg {
  background-color: #21508E !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_azul_claro_bg {
  background-color: #33B5E5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_azul_intermedio_bg {
  background-color: #1565c0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_verde_claro_bg {
  background-color: #9C0 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_naranja_bg {
  background-color: #F80 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blanco_bg {
  background-color: #fff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_marron_claro_bg {
  background-color: #d7ccc8 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_fuxia_bg {
  background-color: #ad1457 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_azul_verdoso_bg {
  background-color: #009688 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_mate_bg {
  background-color: #cddc39 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_negro_bg {
  background-color: #000 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_azul_morado_bg {
  background-color: #5c6bc0 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_verde_oscuro_bg {
  background-color: #1b5e20 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_amarillo_naranja_bg {
  background-color: #ffd600 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_azul_oscuro_bg {
  background-color: #0d47a1 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_rosaceo_bg {
  background-color: #ec407a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amarillo_bg {
  background-color: #ffeb3b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_verde_bg {
  background-color: #690 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_rojo_bg {
  background-color: #C00 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_verde_claro_bg {
  background-color: #cddc39 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_marron_bg {
  background-color: #d35548 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_morado_bg {
  background-color: #e91e63 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_amarillo_naranja_bg {
  background-color: #ffc107 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_amarillo_bg {
  background-color: #ffeb3b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_naranja_oscuro_bg {
  background-color: #ff4336 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_naranja_bg {
  background-color: #ff9800 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_rojo_bg {
  background-color: #f44336 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_azul_claro_bg {
  background-color: #00bcd4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_azul_verdoso_bg {
  background-color: #009688 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_azul_bg {
  background-color: #03a9f4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_azul_intermedio_bg {
  background-color: #2196f3 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_azul_oscuro_bg {
  background-color: #3f51b5 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_verde_oscuro_bg {
  background-color: #4caf50 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_gris_bg {
  background-color: #607d8b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_violeta_bg {
  background-color: #673ab7 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_verde_bg {
  background-color: #8bc34a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_md_fuxia_bg {
  background-color: #9c27b0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_md_gris_claro_bg {
  background-color: #9e9e9e !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_bg {
  background-color: #f44336 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_50_bg {
  background-color: #ffebee !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_100_bg {
  background-color: #ffcdd2 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_200_bg {
  background-color: #ef9a9a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_300_bg {
  background-color: #e57373 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_400_bg {
  background-color: #ef5350 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_500_bg {
  background-color: #f44336 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_600_bg {
  background-color: #e53935 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_700_bg {
  background-color: #d32f2f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_800_bg {
  background-color: #c62828 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_900_bg {
  background-color: #b71c1c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_a100_bg {
  background-color: #ff8a80 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_red_a200_bg {
  background-color: #ff5252 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_a400_bg {
  background-color: #ff1744 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_red_a700_bg {
  background-color: #d50000 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_bg {
  background-color: #e91e63 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_50_bg {
  background-color: #fce4ec !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_pink_100_bg {
  background-color: #f8bbd0 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_pink_200_bg {
  background-color: #f48fb1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_pink_300_bg {
  background-color: #f06292 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_400_bg {
  background-color: #ec407a !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_500_bg {
  background-color: #e91e63 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_600_bg {
  background-color: #d81b60 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_700_bg {
  background-color: #c2185b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_800_bg {
  background-color: #ad1457 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_900_bg {
  background-color: #880e4f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_a100_bg {
  background-color: #ff80ab !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_pink_a200_bg {
  background-color: #ff4081 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_a400_bg {
  background-color: #f50057 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_pink_a700_bg {
  background-color: #c51162 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_bg {
  background-color: #9c27b0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_50_bg {
  background-color: #f3e5f5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_purple_100_bg {
  background-color: #e1bee7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_purple_200_bg {
  background-color: #ce93d8 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_purple_300_bg {
  background-color: #ba68c8 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_400_bg {
  background-color: #ab47bc !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_500_bg {
  background-color: #9c27b0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_600_bg {
  background-color: #8e24aa !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_700_bg {
  background-color: #7b1fa2 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_800_bg {
  background-color: #6a1b9a !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_900_bg {
  background-color: #4a148c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_a100_bg {
  background-color: #ea80fc !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_purple_a200_bg {
  background-color: #e040fb !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_a400_bg {
  background-color: #d500f9 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_purple_a700_bg {
  background-color: #aa00ff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_bg {
  background-color: #673ab7 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_50_bg {
  background-color: #ede7f6 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_100_bg {
  background-color: #d1c4e9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_200_bg {
  background-color: #b39ddb !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_300_bg {
  background-color: #9575cd !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_400_bg {
  background-color: #7e57c2 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_500_bg {
  background-color: #673ab7 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_600_bg {
  background-color: #5e35b1 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_700_bg {
  background-color: #512da8 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_800_bg {
  background-color: #4527a0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_900_bg {
  background-color: #311b92 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_a100_bg {
  background-color: #b388ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_a200_bg {
  background-color: #7c4dff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_a400_bg {
  background-color: #651fff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_purple_a700_bg {
  background-color: #6200ea !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_bg {
  background-color: #3f51b5 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_50_bg {
  background-color: #e8eaf6 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_indigo_100_bg {
  background-color: #c5cae9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_indigo_200_bg {
  background-color: #9fa8da !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_indigo_300_bg {
  background-color: #7986cb !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_400_bg {
  background-color: #5c6bc0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_500_bg {
  background-color: #3f51b5 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_600_bg {
  background-color: #3949ab !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_700_bg {
  background-color: #303f9f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_800_bg {
  background-color: #283593 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_900_bg {
  background-color: #1a237e !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_a100_bg {
  background-color: #8c9eff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_indigo_a200_bg {
  background-color: #536dfe !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_a400_bg {
  background-color: #3d5afe !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_indigo_a700_bg {
  background-color: #304ffe !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_bg {
  background-color: #2196f3 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_50_bg {
  background-color: #e3f2fd !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_100_bg {
  background-color: #bbdefb !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_200_bg {
  background-color: #90caf9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_300_bg {
  background-color: #64b5f6 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_400_bg {
  background-color: #42a5f5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_500_bg {
  background-color: #2196f3 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_600_bg {
  background-color: #1e88e5 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_700_bg {
  background-color: #1976d2 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_800_bg {
  background-color: #1565c0 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_900_bg {
  background-color: #0d47a1 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_a100_bg {
  background-color: #82b1ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_a200_bg {
  background-color: #448aff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_a400_bg {
  background-color: #2979ff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_a700_bg {
  background-color: #2962ff !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_blue_bg {
  background-color: #03a9f4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_50_bg {
  background-color: #e1f5fe !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_100_bg {
  background-color: #b3e5fc !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_200_bg {
  background-color: #81d4fa !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_300_bg {
  background-color: #4fc3f7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_400_bg {
  background-color: #29b6f6 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_500_bg {
  background-color: #03a9f4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_600_bg {
  background-color: #039be5 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_blue_700_bg {
  background-color: #0288d1 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_blue_800_bg {
  background-color: #0277bd !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_blue_900_bg {
  background-color: #01579b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_blue_a100_bg {
  background-color: #80d8ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_a200_bg {
  background-color: #40c4ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_a400_bg {
  background-color: #00b0ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_blue_a700_bg {
  background-color: #0091ea !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_cyan_50_bg {
  background-color: #e0f7fa !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_100_bg {
  background-color: #b2ebf2 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_200_bg {
  background-color: #80deea !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_300_bg {
  background-color: #4dd0e1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_400_bg {
  background-color: #26c6da !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_500_bg {
  background-color: #00bcd4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_600_bg {
  background-color: #00acc1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_700_bg {
  background-color: #0097a7 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_cyan_800_bg {
  background-color: #00838f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_cyan_900_bg {
  background-color: #006064 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_cyan_a100_bg {
  background-color: #84ffff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_a200_bg {
  background-color: #18ffff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_a400_bg {
  background-color: #00e5ff !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_cyan_a700_bg {
  background-color: #00b8d4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_bg {
  background-color: #009688 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_50_bg {
  background-color: #e0f2f1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_100_bg {
  background-color: #b2dfdb !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_200_bg {
  background-color: #80cbc4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_300_bg {
  background-color: #4db6ac !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_400_bg {
  background-color: #26a69a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_500_bg {
  background-color: #009688 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_600_bg {
  background-color: #00897b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_700_bg {
  background-color: #00796b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_800_bg {
  background-color: #00695c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_900_bg {
  background-color: #004d40 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_teal_a100_bg {
  background-color: #a7ffeb !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_a200_bg {
  background-color: #64ffda !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_a400_bg {
  background-color: #1de9b6 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_teal_a700_bg {
  background-color: #00bfa5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_bg {
  background-color: #4caf50 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_50_bg {
  background-color: #e8f5e9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_100_bg {
  background-color: #c8e6c9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_200_bg {
  background-color: #a5d6a7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_300_bg {
  background-color: #81c784 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_400_bg {
  background-color: #66bb6a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_500_bg {
  background-color: #4caf50 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_600_bg {
  background-color: #43a047 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_green_700_bg {
  background-color: #388e3c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_green_800_bg {
  background-color: #2e7d32 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_green_900_bg {
  background-color: #1b5e20 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_green_a100_bg {
  background-color: #b9f6ca !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_a200_bg {
  background-color: #69f0ae !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_a400_bg {
  background-color: #00e676 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_green_a700_bg {
  background-color: #00c853 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_bg {
  background-color: #8bc34a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_50_bg {
  background-color: #f1f8e9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_100_bg {
  background-color: #dcedc8 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_200_bg {
  background-color: #c5e1a5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_300_bg {
  background-color: #aed581 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_400_bg {
  background-color: #9ccc65 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_500_bg {
  background-color: #8bc34a !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_600_bg {
  background-color: #7cb342 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_700_bg {
  background-color: #689f38 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_green_800_bg {
  background-color: #558b2f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_green_900_bg {
  background-color: #33691e !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_light_green_a100_bg {
  background-color: #ccff90 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_a200_bg {
  background-color: #b2ff59 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_a400_bg {
  background-color: #76ff03 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_light_green_a700_bg {
  background-color: #64dd17 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_bg {
  background-color: #cddc39 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_50_bg {
  background-color: #f9fbe7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_100_bg {
  background-color: #f0f4c3 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_200_bg {
  background-color: #e6ee9c !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_300_bg {
  background-color: #dce775 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_400_bg {
  background-color: #d4e157 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_500_bg {
  background-color: #cddc39 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_600_bg {
  background-color: #c0ca33 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_700_bg {
  background-color: #afb42b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_800_bg {
  background-color: #9e9d24 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_900_bg {
  background-color: #827717 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_lime_a100_bg {
  background-color: #f4ff81 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_a200_bg {
  background-color: #eeff41 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_a400_bg {
  background-color: #c6ff00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_lime_a700_bg {
  background-color: #aeea00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_bg {
  background-color: #ffeb3b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_50_bg {
  background-color: #fffde7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_100_bg {
  background-color: #fff9c4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_200_bg {
  background-color: #fff59d !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_300_bg {
  background-color: #fff176 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_400_bg {
  background-color: #ffee58 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_500_bg {
  background-color: #ffeb3b !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_600_bg {
  background-color: #fdd835 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_700_bg {
  background-color: #fbc02d !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_800_bg {
  background-color: #f9a825 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_900_bg {
  background-color: #f57f17 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_a100_bg {
  background-color: #ffff8d !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_a200_bg {
  background-color: #ffff00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_a400_bg {
  background-color: #ffea00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_yellow_a700_bg {
  background-color: #ffd600 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_bg {
  background-color: #ffc107 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_50_bg {
  background-color: #fff8e1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_100_bg {
  background-color: #ffecb3 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_200_bg {
  background-color: #ffe082 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_300_bg {
  background-color: #ffd54f !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_400_bg {
  background-color: #ffca28 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_500_bg {
  background-color: #ffc107 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_600_bg {
  background-color: #ffb300 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_700_bg {
  background-color: #ffa000 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_800_bg {
  background-color: #ff8f00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_900_bg {
  background-color: #ff6f00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_a100_bg {
  background-color: #ffe57f !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_a200_bg {
  background-color: #ffd740 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_a400_bg {
  background-color: #ffc400 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_amber_a700_bg {
  background-color: #ffab00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_bg {
  background-color: #ff9800 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_50_bg {
  background-color: #fff3e0 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_100_bg {
  background-color: #ffe0b2 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_200_bg {
  background-color: #ffcc80 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_300_bg {
  background-color: #ffb74d !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_400_bg {
  background-color: #ffa726 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_500_bg {
  background-color: #ff9800 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_600_bg {
  background-color: #fb8c00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_700_bg {
  background-color: #f57c00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_800_bg {
  background-color: #ef6c00 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_orange_900_bg {
  background-color: #e65100 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_orange_a100_bg {
  background-color: #ffd180 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_a200_bg {
  background-color: #ffab40 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_a400_bg {
  background-color: #ff9100 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_orange_a700_bg {
  background-color: #ff6d00 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_bg {
  background-color: #ff5722 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_50_bg {
  background-color: #fbe9e7 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_100_bg {
  background-color: #ffccbc !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_200_bg {
  background-color: #ffab91 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_300_bg {
  background-color: #ff8a65 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_400_bg {
  background-color: #ff7043 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_500_bg {
  background-color: #ff5722 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_600_bg {
  background-color: #f4511e !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_700_bg {
  background-color: #e64a19 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_800_bg {
  background-color: #d84315 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_900_bg {
  background-color: #bf360c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_a100_bg {
  background-color: #ff9e80 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_a200_bg {
  background-color: #ff6e40 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_a400_bg {
  background-color: #ff3d00 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_deep_orange_a700_bg {
  background-color: #dd2c00 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_bg {
  background-color: #795548 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_50_bg {
  background-color: #efebe9 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_brown_100_bg {
  background-color: #d7ccc8 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_brown_200_bg {
  background-color: #bcaaa4 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_brown_300_bg {
  background-color: #a1887f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_400_bg {
  background-color: #8d6e63 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_500_bg {
  background-color: #795548 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_600_bg {
  background-color: #6d4c41 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_700_bg {
  background-color: #5d4037 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_800_bg {
  background-color: #4e342e !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_brown_900_bg {
  background-color: #3e2723 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_grey_bg {
  background-color: #9e9e9e !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_50_bg {
  background-color: #fafafa !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_100_bg {
  background-color: #f5f5f5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_200_bg {
  background-color: #eeeeee !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_300_bg {
  background-color: #e0e0e0 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_400_bg {
  background-color: #bdbdbd !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_500_bg {
  background-color: #9e9e9e !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_grey_600_bg {
  background-color: #757575 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_grey_700_bg {
  background-color: #616161 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_grey_800_bg {
  background-color: #424242 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_grey_900_bg {
  background-color: #212121 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_bg {
  background-color: #607d8b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_50_bg {
  background-color: #eceff1 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_100_bg {
  background-color: #cfd8dc !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_200_bg {
  background-color: #b0bec5 !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_300_bg {
  background-color: #90a4ae !important;
  color: #000 !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_400_bg {
  background-color: #78909c !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_500_bg {
  background-color: #607d8b !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_600_bg {
  background-color: #546e7a !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_700_bg {
  background-color: #455a64 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_800_bg {
  background-color: #37474f !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_blue_grey_900_bg {
  background-color: #263238 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.color_libra_marron_bd {
  border-color: #795548 !important;
}
.color_libra_marron_oscuro_bd {
  border-color: #3e2723 !important;
}
.color_libra_marron_intermedio_bd {
  border-color: #a1887f !important;
}
.color_libra_morado_bd {
  border-color: #9c27b0 !important;
}
.color_libra_cyan_bd {
  border-color: #00bcd4 !important;
}
.color_libra_azul_bd {
  border-color: #21508E !important;
}
.color_libra_azul_claro_bd {
  border-color: #33B5E5 !important;
}
.color_libra_azul_intermedio_bd {
  border-color: #1565c0 !important;
}
.color_libra_verde_claro_bd {
  border-color: #9C0 !important;
}
.color_libra_naranja_bd {
  border-color: #F80 !important;
}
.color_libra_blanco_bd {
  border-color: #fff !important;
}
.color_libra_marron_claro_bd {
  border-color: #d7ccc8 !important;
}
.color_libra_fuxia_bd {
  border-color: #ad1457 !important;
}
.color_libra_azul_verdoso_bd {
  border-color: #009688 !important;
}
.color_libra_mate_bd {
  border-color: #cddc39 !important;
}
.color_libra_negro_bd {
  border-color: #000 !important;
}
.color_libra_azul_morado_bd {
  border-color: #5c6bc0 !important;
}
.color_libra_verde_oscuro_bd {
  border-color: #1b5e20 !important;
}
.color_libra_amarillo_naranja_bd {
  border-color: #ffd600 !important;
}
.color_libra_azul_oscuro_bd {
  border-color: #0d47a1 !important;
}
.color_libra_rosaceo_bd {
  border-color: #ec407a !important;
}
.color_libra_amarillo_bd {
  border-color: #ffeb3b !important;
}
.color_libra_verde_bd {
  border-color: #690 !important;
}
.color_libra_rojo_bd {
  border-color: #C00 !important;
}
.color_libra_md_verde_claro_bd {
  border-color: #cddc39 !important;
}
.color_libra_md_marron_bd {
  border-color: #d35548 !important;
}
.color_libra_md_morado_bd {
  border-color: #e91e63 !important;
}
.color_libra_md_amarillo_naranja_bd {
  border-color: #ffc107 !important;
}
.color_libra_md_amarillo_bd {
  border-color: #ffeb3b !important;
}
.color_libra_md_naranja_oscuro_bd {
  border-color: #ff4336 !important;
}
.color_libra_md_naranja_bd {
  border-color: #ff9800 !important;
}
.color_libra_md_rojo_bd {
  border-color: #f44336 !important;
}
.color_libra_md_azul_claro_bd {
  border-color: #00bcd4 !important;
}
.color_libra_md_azul_verdoso_bd {
  border-color: #009688 !important;
}
.color_libra_md_azul_bd {
  border-color: #03a9f4 !important;
}
.color_libra_md_azul_intermedio_bd {
  border-color: #2196f3 !important;
}
.color_libra_md_azul_oscuro_bd {
  border-color: #3f51b5 !important;
}
.color_libra_md_verde_oscuro_bd {
  border-color: #4caf50 !important;
}
.color_libra_md_gris_bd {
  border-color: #607d8b !important;
}
.color_libra_md_violeta_bd {
  border-color: #673ab7 !important;
}
.color_libra_md_verde_bd {
  border-color: #8bc34a !important;
}
.color_libra_md_fuxia_bd {
  border-color: #9c27b0 !important;
}
.color_libra_md_gris_claro_bd {
  border-color: #9e9e9e !important;
}
.color_libra_red_bd {
  border-color: #f44336 !important;
}
.color_libra_red_50_bd {
  border-color: #ffebee !important;
}
.color_libra_red_100_bd {
  border-color: #ffcdd2 !important;
}
.color_libra_red_200_bd {
  border-color: #ef9a9a !important;
}
.color_libra_red_300_bd {
  border-color: #e57373 !important;
}
.color_libra_red_400_bd {
  border-color: #ef5350 !important;
}
.color_libra_red_500_bd {
  border-color: #f44336 !important;
}
.color_libra_red_600_bd {
  border-color: #e53935 !important;
}
.color_libra_red_700_bd {
  border-color: #d32f2f !important;
}
.color_libra_red_800_bd {
  border-color: #c62828 !important;
}
.color_libra_red_900_bd {
  border-color: #b71c1c !important;
}
.color_libra_red_a100_bd {
  border-color: #ff8a80 !important;
}
.color_libra_red_a200_bd {
  border-color: #ff5252 !important;
}
.color_libra_red_a400_bd {
  border-color: #ff1744 !important;
}
.color_libra_red_a700_bd {
  border-color: #d50000 !important;
}
.color_libra_pink_bd {
  border-color: #e91e63 !important;
}
.color_libra_pink_50_bd {
  border-color: #fce4ec !important;
}
.color_libra_pink_100_bd {
  border-color: #f8bbd0 !important;
}
.color_libra_pink_200_bd {
  border-color: #f48fb1 !important;
}
.color_libra_pink_300_bd {
  border-color: #f06292 !important;
}
.color_libra_pink_400_bd {
  border-color: #ec407a !important;
}
.color_libra_pink_500_bd {
  border-color: #e91e63 !important;
}
.color_libra_pink_600_bd {
  border-color: #d81b60 !important;
}
.color_libra_pink_700_bd {
  border-color: #c2185b !important;
}
.color_libra_pink_800_bd {
  border-color: #ad1457 !important;
}
.color_libra_pink_900_bd {
  border-color: #880e4f !important;
}
.color_libra_pink_a100_bd {
  border-color: #ff80ab !important;
}
.color_libra_pink_a200_bd {
  border-color: #ff4081 !important;
}
.color_libra_pink_a400_bd {
  border-color: #f50057 !important;
}
.color_libra_pink_a700_bd {
  border-color: #c51162 !important;
}
.color_libra_purple_bd {
  border-color: #9c27b0 !important;
}
.color_libra_purple_50_bd {
  border-color: #f3e5f5 !important;
}
.color_libra_purple_100_bd {
  border-color: #e1bee7 !important;
}
.color_libra_purple_200_bd {
  border-color: #ce93d8 !important;
}
.color_libra_purple_300_bd {
  border-color: #ba68c8 !important;
}
.color_libra_purple_400_bd {
  border-color: #ab47bc !important;
}
.color_libra_purple_500_bd {
  border-color: #9c27b0 !important;
}
.color_libra_purple_600_bd {
  border-color: #8e24aa !important;
}
.color_libra_purple_700_bd {
  border-color: #7b1fa2 !important;
}
.color_libra_purple_800_bd {
  border-color: #6a1b9a !important;
}
.color_libra_purple_900_bd {
  border-color: #4a148c !important;
}
.color_libra_purple_a100_bd {
  border-color: #ea80fc !important;
}
.color_libra_purple_a200_bd {
  border-color: #e040fb !important;
}
.color_libra_purple_a400_bd {
  border-color: #d500f9 !important;
}
.color_libra_purple_a700_bd {
  border-color: #aa00ff !important;
}
.color_libra_deep_purple_bd {
  border-color: #673ab7 !important;
}
.color_libra_deep_purple_50_bd {
  border-color: #ede7f6 !important;
}
.color_libra_deep_purple_100_bd {
  border-color: #d1c4e9 !important;
}
.color_libra_deep_purple_200_bd {
  border-color: #b39ddb !important;
}
.color_libra_deep_purple_300_bd {
  border-color: #9575cd !important;
}
.color_libra_deep_purple_400_bd {
  border-color: #7e57c2 !important;
}
.color_libra_deep_purple_500_bd {
  border-color: #673ab7 !important;
}
.color_libra_deep_purple_600_bd {
  border-color: #5e35b1 !important;
}
.color_libra_deep_purple_700_bd {
  border-color: #512da8 !important;
}
.color_libra_deep_purple_800_bd {
  border-color: #4527a0 !important;
}
.color_libra_deep_purple_900_bd {
  border-color: #311b92 !important;
}
.color_libra_deep_purple_a100_bd {
  border-color: #b388ff !important;
}
.color_libra_deep_purple_a200_bd {
  border-color: #7c4dff !important;
}
.color_libra_deep_purple_a400_bd {
  border-color: #651fff !important;
}
.color_libra_deep_purple_a700_bd {
  border-color: #6200ea !important;
}
.color_libra_indigo_bd {
  border-color: #3f51b5 !important;
}
.color_libra_indigo_50_bd {
  border-color: #e8eaf6 !important;
}
.color_libra_indigo_100_bd {
  border-color: #c5cae9 !important;
}
.color_libra_indigo_200_bd {
  border-color: #9fa8da !important;
}
.color_libra_indigo_300_bd {
  border-color: #7986cb !important;
}
.color_libra_indigo_400_bd {
  border-color: #5c6bc0 !important;
}
.color_libra_indigo_500_bd {
  border-color: #3f51b5 !important;
}
.color_libra_indigo_600_bd {
  border-color: #3949ab !important;
}
.color_libra_indigo_700_bd {
  border-color: #303f9f !important;
}
.color_libra_indigo_800_bd {
  border-color: #283593 !important;
}
.color_libra_indigo_900_bd {
  border-color: #1a237e !important;
}
.color_libra_indigo_a100_bd {
  border-color: #8c9eff !important;
}
.color_libra_indigo_a200_bd {
  border-color: #536dfe !important;
}
.color_libra_indigo_a400_bd {
  border-color: #3d5afe !important;
}
.color_libra_indigo_a700_bd {
  border-color: #304ffe !important;
}
.color_libra_blue_bd {
  border-color: #2196f3 !important;
}
.color_libra_blue_50_bd {
  border-color: #e3f2fd !important;
}
.color_libra_blue_100_bd {
  border-color: #bbdefb !important;
}
.color_libra_blue_200_bd {
  border-color: #90caf9 !important;
}
.color_libra_blue_300_bd {
  border-color: #64b5f6 !important;
}
.color_libra_blue_400_bd {
  border-color: #42a5f5 !important;
}
.color_libra_blue_500_bd {
  border-color: #2196f3 !important;
}
.color_libra_blue_600_bd {
  border-color: #1e88e5 !important;
}
.color_libra_blue_700_bd {
  border-color: #1976d2 !important;
}
.color_libra_blue_800_bd {
  border-color: #1565c0 !important;
}
.color_libra_blue_900_bd {
  border-color: #0d47a1 !important;
}
.color_libra_blue_a100_bd {
  border-color: #82b1ff !important;
}
.color_libra_blue_a200_bd {
  border-color: #448aff !important;
}
.color_libra_blue_a400_bd {
  border-color: #2979ff !important;
}
.color_libra_blue_a700_bd {
  border-color: #2962ff !important;
}
.color_libra_light_blue_bd {
  border-color: #03a9f4 !important;
}
.color_libra_light_blue_50_bd {
  border-color: #e1f5fe !important;
}
.color_libra_light_blue_100_bd {
  border-color: #b3e5fc !important;
}
.color_libra_light_blue_200_bd {
  border-color: #81d4fa !important;
}
.color_libra_light_blue_300_bd {
  border-color: #4fc3f7 !important;
}
.color_libra_light_blue_400_bd {
  border-color: #29b6f6 !important;
}
.color_libra_light_blue_500_bd {
  border-color: #03a9f4 !important;
}
.color_libra_light_blue_600_bd {
  border-color: #039be5 !important;
}
.color_libra_light_blue_700_bd {
  border-color: #0288d1 !important;
}
.color_libra_light_blue_800_bd {
  border-color: #0277bd !important;
}
.color_libra_light_blue_900_bd {
  border-color: #01579b !important;
}
.color_libra_light_blue_a100_bd {
  border-color: #80d8ff !important;
}
.color_libra_light_blue_a200_bd {
  border-color: #40c4ff !important;
}
.color_libra_light_blue_a400_bd {
  border-color: #00b0ff !important;
}
.color_libra_light_blue_a700_bd {
  border-color: #0091ea !important;
}
.color_libra_cyan_50_bd {
  border-color: #e0f7fa !important;
}
.color_libra_cyan_100_bd {
  border-color: #b2ebf2 !important;
}
.color_libra_cyan_200_bd {
  border-color: #80deea !important;
}
.color_libra_cyan_300_bd {
  border-color: #4dd0e1 !important;
}
.color_libra_cyan_400_bd {
  border-color: #26c6da !important;
}
.color_libra_cyan_500_bd {
  border-color: #00bcd4 !important;
}
.color_libra_cyan_600_bd {
  border-color: #00acc1 !important;
}
.color_libra_cyan_700_bd {
  border-color: #0097a7 !important;
}
.color_libra_cyan_800_bd {
  border-color: #00838f !important;
}
.color_libra_cyan_900_bd {
  border-color: #006064 !important;
}
.color_libra_cyan_a100_bd {
  border-color: #84ffff !important;
}
.color_libra_cyan_a200_bd {
  border-color: #18ffff !important;
}
.color_libra_cyan_a400_bd {
  border-color: #00e5ff !important;
}
.color_libra_cyan_a700_bd {
  border-color: #00b8d4 !important;
}
.color_libra_teal_bd {
  border-color: #009688 !important;
}
.color_libra_teal_50_bd {
  border-color: #e0f2f1 !important;
}
.color_libra_teal_100_bd {
  border-color: #b2dfdb !important;
}
.color_libra_teal_200_bd {
  border-color: #80cbc4 !important;
}
.color_libra_teal_300_bd {
  border-color: #4db6ac !important;
}
.color_libra_teal_400_bd {
  border-color: #26a69a !important;
}
.color_libra_teal_500_bd {
  border-color: #009688 !important;
}
.color_libra_teal_600_bd {
  border-color: #00897b !important;
}
.color_libra_teal_700_bd {
  border-color: #00796b !important;
}
.color_libra_teal_800_bd {
  border-color: #00695c !important;
}
.color_libra_teal_900_bd {
  border-color: #004d40 !important;
}
.color_libra_teal_a100_bd {
  border-color: #a7ffeb !important;
}
.color_libra_teal_a200_bd {
  border-color: #64ffda !important;
}
.color_libra_teal_a400_bd {
  border-color: #1de9b6 !important;
}
.color_libra_teal_a700_bd {
  border-color: #00bfa5 !important;
}
.color_libra_green_bd {
  border-color: #4caf50 !important;
}
.color_libra_green_50_bd {
  border-color: #e8f5e9 !important;
}
.color_libra_green_100_bd {
  border-color: #c8e6c9 !important;
}
.color_libra_green_200_bd {
  border-color: #a5d6a7 !important;
}
.color_libra_green_300_bd {
  border-color: #81c784 !important;
}
.color_libra_green_400_bd {
  border-color: #66bb6a !important;
}
.color_libra_green_500_bd {
  border-color: #4caf50 !important;
}
.color_libra_green_600_bd {
  border-color: #43a047 !important;
}
.color_libra_green_700_bd {
  border-color: #388e3c !important;
}
.color_libra_green_800_bd {
  border-color: #2e7d32 !important;
}
.color_libra_green_900_bd {
  border-color: #1b5e20 !important;
}
.color_libra_green_a100_bd {
  border-color: #b9f6ca !important;
}
.color_libra_green_a200_bd {
  border-color: #69f0ae !important;
}
.color_libra_green_a400_bd {
  border-color: #00e676 !important;
}
.color_libra_green_a700_bd {
  border-color: #00c853 !important;
}
.color_libra_light_green_bd {
  border-color: #8bc34a !important;
}
.color_libra_light_green_50_bd {
  border-color: #f1f8e9 !important;
}
.color_libra_light_green_100_bd {
  border-color: #dcedc8 !important;
}
.color_libra_light_green_200_bd {
  border-color: #c5e1a5 !important;
}
.color_libra_light_green_300_bd {
  border-color: #aed581 !important;
}
.color_libra_light_green_400_bd {
  border-color: #9ccc65 !important;
}
.color_libra_light_green_500_bd {
  border-color: #8bc34a !important;
}
.color_libra_light_green_600_bd {
  border-color: #7cb342 !important;
}
.color_libra_light_green_700_bd {
  border-color: #689f38 !important;
}
.color_libra_light_green_800_bd {
  border-color: #558b2f !important;
}
.color_libra_light_green_900_bd {
  border-color: #33691e !important;
}
.color_libra_light_green_a100_bd {
  border-color: #ccff90 !important;
}
.color_libra_light_green_a200_bd {
  border-color: #b2ff59 !important;
}
.color_libra_light_green_a400_bd {
  border-color: #76ff03 !important;
}
.color_libra_light_green_a700_bd {
  border-color: #64dd17 !important;
}
.color_libra_lime_bd {
  border-color: #cddc39 !important;
}
.color_libra_lime_50_bd {
  border-color: #f9fbe7 !important;
}
.color_libra_lime_100_bd {
  border-color: #f0f4c3 !important;
}
.color_libra_lime_200_bd {
  border-color: #e6ee9c !important;
}
.color_libra_lime_300_bd {
  border-color: #dce775 !important;
}
.color_libra_lime_400_bd {
  border-color: #d4e157 !important;
}
.color_libra_lime_500_bd {
  border-color: #cddc39 !important;
}
.color_libra_lime_600_bd {
  border-color: #c0ca33 !important;
}
.color_libra_lime_700_bd {
  border-color: #afb42b !important;
}
.color_libra_lime_800_bd {
  border-color: #9e9d24 !important;
}
.color_libra_lime_900_bd {
  border-color: #827717 !important;
}
.color_libra_lime_a100_bd {
  border-color: #f4ff81 !important;
}
.color_libra_lime_a200_bd {
  border-color: #eeff41 !important;
}
.color_libra_lime_a400_bd {
  border-color: #c6ff00 !important;
}
.color_libra_lime_a700_bd {
  border-color: #aeea00 !important;
}
.color_libra_yellow_bd {
  border-color: #ffeb3b !important;
}
.color_libra_yellow_50_bd {
  border-color: #fffde7 !important;
}
.color_libra_yellow_100_bd {
  border-color: #fff9c4 !important;
}
.color_libra_yellow_200_bd {
  border-color: #fff59d !important;
}
.color_libra_yellow_300_bd {
  border-color: #fff176 !important;
}
.color_libra_yellow_400_bd {
  border-color: #ffee58 !important;
}
.color_libra_yellow_500_bd {
  border-color: #ffeb3b !important;
}
.color_libra_yellow_600_bd {
  border-color: #fdd835 !important;
}
.color_libra_yellow_700_bd {
  border-color: #fbc02d !important;
}
.color_libra_yellow_800_bd {
  border-color: #f9a825 !important;
}
.color_libra_yellow_900_bd {
  border-color: #f57f17 !important;
}
.color_libra_yellow_a100_bd {
  border-color: #ffff8d !important;
}
.color_libra_yellow_a200_bd {
  border-color: #ffff00 !important;
}
.color_libra_yellow_a400_bd {
  border-color: #ffea00 !important;
}
.color_libra_yellow_a700_bd {
  border-color: #ffd600 !important;
}
.color_libra_amber_bd {
  border-color: #ffc107 !important;
}
.color_libra_amber_50_bd {
  border-color: #fff8e1 !important;
}
.color_libra_amber_100_bd {
  border-color: #ffecb3 !important;
}
.color_libra_amber_200_bd {
  border-color: #ffe082 !important;
}
.color_libra_amber_300_bd {
  border-color: #ffd54f !important;
}
.color_libra_amber_400_bd {
  border-color: #ffca28 !important;
}
.color_libra_amber_500_bd {
  border-color: #ffc107 !important;
}
.color_libra_amber_600_bd {
  border-color: #ffb300 !important;
}
.color_libra_amber_700_bd {
  border-color: #ffa000 !important;
}
.color_libra_amber_800_bd {
  border-color: #ff8f00 !important;
}
.color_libra_amber_900_bd {
  border-color: #ff6f00 !important;
}
.color_libra_amber_a100_bd {
  border-color: #ffe57f !important;
}
.color_libra_amber_a200_bd {
  border-color: #ffd740 !important;
}
.color_libra_amber_a400_bd {
  border-color: #ffc400 !important;
}
.color_libra_amber_a700_bd {
  border-color: #ffab00 !important;
}
.color_libra_orange_bd {
  border-color: #ff9800 !important;
}
.color_libra_orange_50_bd {
  border-color: #fff3e0 !important;
}
.color_libra_orange_100_bd {
  border-color: #ffe0b2 !important;
}
.color_libra_orange_200_bd {
  border-color: #ffcc80 !important;
}
.color_libra_orange_300_bd {
  border-color: #ffb74d !important;
}
.color_libra_orange_400_bd {
  border-color: #ffa726 !important;
}
.color_libra_orange_500_bd {
  border-color: #ff9800 !important;
}
.color_libra_orange_600_bd {
  border-color: #fb8c00 !important;
}
.color_libra_orange_700_bd {
  border-color: #f57c00 !important;
}
.color_libra_orange_800_bd {
  border-color: #ef6c00 !important;
}
.color_libra_orange_900_bd {
  border-color: #e65100 !important;
}
.color_libra_orange_a100_bd {
  border-color: #ffd180 !important;
}
.color_libra_orange_a200_bd {
  border-color: #ffab40 !important;
}
.color_libra_orange_a400_bd {
  border-color: #ff9100 !important;
}
.color_libra_orange_a700_bd {
  border-color: #ff6d00 !important;
}
.color_libra_deep_orange_bd {
  border-color: #ff5722 !important;
}
.color_libra_deep_orange_50_bd {
  border-color: #fbe9e7 !important;
}
.color_libra_deep_orange_100_bd {
  border-color: #ffccbc !important;
}
.color_libra_deep_orange_200_bd {
  border-color: #ffab91 !important;
}
.color_libra_deep_orange_300_bd {
  border-color: #ff8a65 !important;
}
.color_libra_deep_orange_400_bd {
  border-color: #ff7043 !important;
}
.color_libra_deep_orange_500_bd {
  border-color: #ff5722 !important;
}
.color_libra_deep_orange_600_bd {
  border-color: #f4511e !important;
}
.color_libra_deep_orange_700_bd {
  border-color: #e64a19 !important;
}
.color_libra_deep_orange_800_bd {
  border-color: #d84315 !important;
}
.color_libra_deep_orange_900_bd {
  border-color: #bf360c !important;
}
.color_libra_deep_orange_a100_bd {
  border-color: #ff9e80 !important;
}
.color_libra_deep_orange_a200_bd {
  border-color: #ff6e40 !important;
}
.color_libra_deep_orange_a400_bd {
  border-color: #ff3d00 !important;
}
.color_libra_deep_orange_a700_bd {
  border-color: #dd2c00 !important;
}
.color_libra_brown_bd {
  border-color: #795548 !important;
}
.color_libra_brown_50_bd {
  border-color: #efebe9 !important;
}
.color_libra_brown_100_bd {
  border-color: #d7ccc8 !important;
}
.color_libra_brown_200_bd {
  border-color: #bcaaa4 !important;
}
.color_libra_brown_300_bd {
  border-color: #a1887f !important;
}
.color_libra_brown_400_bd {
  border-color: #8d6e63 !important;
}
.color_libra_brown_500_bd {
  border-color: #795548 !important;
}
.color_libra_brown_600_bd {
  border-color: #6d4c41 !important;
}
.color_libra_brown_700_bd {
  border-color: #5d4037 !important;
}
.color_libra_brown_800_bd {
  border-color: #4e342e !important;
}
.color_libra_brown_900_bd {
  border-color: #3e2723 !important;
}
.color_libra_grey_bd {
  border-color: #9e9e9e !important;
}
.color_libra_grey_50_bd {
  border-color: #fafafa !important;
}
.color_libra_grey_100_bd {
  border-color: #f5f5f5 !important;
}
.color_libra_grey_200_bd {
  border-color: #eeeeee !important;
}
.color_libra_grey_300_bd {
  border-color: #e0e0e0 !important;
}
.color_libra_grey_400_bd {
  border-color: #bdbdbd !important;
}
.color_libra_grey_500_bd {
  border-color: #9e9e9e !important;
}
.color_libra_grey_600_bd {
  border-color: #757575 !important;
}
.color_libra_grey_700_bd {
  border-color: #616161 !important;
}
.color_libra_grey_800_bd {
  border-color: #424242 !important;
}
.color_libra_grey_900_bd {
  border-color: #212121 !important;
}
.color_libra_blue_grey_bd {
  border-color: #607d8b !important;
}
.color_libra_blue_grey_50_bd {
  border-color: #eceff1 !important;
}
.color_libra_blue_grey_100_bd {
  border-color: #cfd8dc !important;
}
.color_libra_blue_grey_200_bd {
  border-color: #b0bec5 !important;
}
.color_libra_blue_grey_300_bd {
  border-color: #90a4ae !important;
}
.color_libra_blue_grey_400_bd {
  border-color: #78909c !important;
}
.color_libra_blue_grey_500_bd {
  border-color: #607d8b !important;
}
.color_libra_blue_grey_600_bd {
  border-color: #546e7a !important;
}
.color_libra_blue_grey_700_bd {
  border-color: #455a64 !important;
}
.color_libra_blue_grey_800_bd {
  border-color: #37474f !important;
}
.color_libra_blue_grey_900_bd {
  border-color: #263238 !important;
}
