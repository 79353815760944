.insertedHtml {
  margin-top: 1em;
  line-height: 2.4em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
    margin-bottom: 0.4em;
  }

  ul,
  ol {
    margin-left: 1.5em;
  }

  pre {
    padding: 1em 1.5em;
    background: var(--w-e-toolbar-border-color);
    /* border: 1px solid var(--w-e-textarea-slight-color); */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    code {
      tab-size: 1;
      font-weight: 600;
      font-family: monospace;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
  }
  th {
    background: var(--w-e-textarea-bg-color);
    /* background: red; */
  }

  th,
  td {
    border: 1px solid var(--w-e-toolbar-border-color);
    padding: 0.4em 1em;
  }

  input[type='checkbox'] {
    margin-right: 1em;
  }
}
