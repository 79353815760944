.calendar {
  background: var(--calendar-background) !important;
  padding: 0 !important;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  .calendar-header {
    border: none !important;
    background: var(--calendar-header-background) !important;

    .year-title {
      font-weight: 500 !important;
      transition: 0.3s ease-in-out !important;
      color: var(--calendar-header-color) !important;
      opacity: 1 !important;
      &:hover {
        background: var(--calendar-header-hover) !important;
        color: var(--calendar-header-hover-color) !important;
        opacity: 1 !important;
      }
      &[class*='year-neighbor'] {
        opacity: 0.2 !important;
        &:hover {
          background: var(--calendar-header-hover) !important;
          color: var(--calendar-header-hover-color) !important;
          opacity: 1 !important;
          // background: red !important;
        }
      }
    }

    .prev,
    .next {
      transition: 0.3s;
      opacity: 0.5 !important;
      &:hover {
        background: var(--calendar-header-hover) !important;
        color: var(--calendar-header-hover-color) !important;
        opacity: 1 !important;
      }
    }

    .disabled {
      display: none !important;
    }
  }
  .months-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(max(250px, 100%/6), 1fr));
    align-items: center;
    justify-content: center;
    padding: 1em !important;

    .month-container {
      width: 100% !important;
      padding: 1em !important;
      table {
        aspect-ratio: 107 / 109;
        width: 100% !important;

        [data-tooltip] {
          &:hover {
            position: relative;

            &::after {
              display: block;
              content: attr(data-tooltip);
              width: fit-content;
              min-width: fit-content;
              padding: 10px 30px;
              font-weight: 400;
              bottom: 115%;
              left: -150%;
              line-height: 2em;
              white-space: pre;
              border-radius: 5px;
              position: absolute;
              text-align: left;
              font-size: 1.3em;
              box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}
